module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer class="root-footer">\r\n<div class="footer">\r\n<div class="container">\r\n<div class="cont">\r\n<div class="left">\r\n<div class="contact">\r\n<h1>联系电话</h1>\r\n<h2><a href="tel:+053255755771">0532-55755771</a></h2>\r\n<div>\r\n<p>青岛市崂山区松岭路399号</p>\r\n<p>服务邮箱：<a href="mailto:hiview@hi-image.cn">hiview@hi-image.cn</a></p>\r\n<p>给我们留言</p>\r\n</div>\r\n</div>\r\n<div class="ewm-cont">\r\n<div class="ewm-item">\r\n<div class="icon has-svg-circle">\r\n<i class="iconfont iconweixin"></i>\r\n<svg>\r\n<rect></rect>\r\n<rect></rect>\r\n</svg>\r\n</div>\r\n<div class="img"><img src="' +
((__t = (require('../../assets/images/ewm_wx.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n</div>\r\n<div class="ewm-item">\r\n<div class="icon has-svg-circle">\r\n<i class="iconfont icondouyin"></i>\r\n<svg>\r\n<rect></rect>\r\n<rect></rect>\r\n</svg>\r\n</div>\r\n<div class="img"><img src="' +
((__t = (require('../../assets/images/ewm_dy.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n</div>\r\n</div>\r\n</div>\r\n<div class="right">\r\n<div class="title">产品</div>\r\n<div class="footer-link">\r\n<div class="link-item">\r\n<div class="link-tit">显示芯片</div>\r\n<ul>\r\n<li><a href="#" target="_blank">TV TCON</a></li>\r\n<li><a href="#" target="_blank">画质芯片</a></li>\r\n<li><a href="#" target="_blank">Monitor TCON</a></li>\r\n</ul>\r\n</div>\r\n<div class="link-item">\r\n<div class="link-tit">智能SOC芯片</div>\r\n<ul>\r\n<li><a href="#" target="_blank">32 位MCU</a></li>\r\n<li><a href="#" target="_blank">离线语音识别</a></li>\r\n<li><a href="#" target="_blank">智能SOC</a></li>\r\n</ul>\r\n</div>\r\n</div>\r\n</div>\r\n</div>\r\n<div class="copyright">\r\n<span>Copyright© 2020 太阳集团1088vip版权所有</span>\r\n<div class="right">\r\n<a href="#" target="_blank">鲁ICP备 xxxxxxx 号</a>\r\n<div class="map" data-open="map">网站地图</div>\r\n</div>\r\n\r\n</div>\r\n</div>\r\n<div class="backtop">\r\n<div class="backtop-icon has-svg-circle">\r\n<i class="iconfont iconarrowup"></i>\r\n<svg>\r\n<rect></rect>\r\n<rect></rect>\r\n</svg>\r\n</div>\r\n<div class="backtop-text">top</div>\r\n</div>\r\n</div>\r\n</footer>';

}
return __p
}